import Swiper from 'swiper';
import 'swiper/css';
import { map } from '@scripts/map';

window.addEventListener("load", () => {
  const homeActu = new Swiper('[data-home-actu]', {
    "slidesPerView": 1.1,
    "spaceBetween": 16,
    "breakpoints": {
      "576": {
        "slidesPerView": 1.5,
      },
      "768": {
        "slidesPerView": 2.1,
        "spaceBetween": 24,
      },
      "1300": {
        "slidesPerView": 3,
      }
    },
  });
});

document.addEventListener(
  "DOMContentLoaded",
  () => {
    require('./styles/home.scss');
  },
  { once: true },
);
